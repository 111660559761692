/* DateDisplay.css */
.date-display {
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Выровняем по началу */
    color: rgb(1,107,42);
    font-family: 'ModulordRegular', 'PT Mono', monospace;
    font-size: 16px;
    margin-top: 20px;
    padding-left: 20px; /* Отступ слева, чтобы совпадал с текстом */
    align-items: center;
  }
  
  .date-display .icon {
    margin-right: 10px;
    font-size: 20px;
    width: 25px;
    height: 21px;
  }
  
  .date-display .text {
    color: rgb(1,107,42);
    font-size: 1.5rem;
  }
  