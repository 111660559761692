
@font-face {
  font-family: 'ModulordRegular';
  src: url('/public/fonts/Modulord.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PT Mono';
  src: url('/public/fonts/IBM_Plex_Mono/IBMPlexMono-Regular.ttf') format('truetype');

}

.carousel-container {
  display: flex;
  overflow-x: scroll;
  overflow-x: hidden;
  white-space: nowrap;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  font-family: 'PT Mono', monospace;
  height: 250px;
  cursor: grab;
  scrollbar-width: thin;
  margin-left: -1rem;
  margin-right: -2rem;
position: relative;
}
.carousel-container::-webkit-scrollbar {
  display: none;
}

.carousel-container:hover::-webkit-scrollbar {
  display: none !important;
}

.carousel-container.dragging {
  cursor: grabbing; /* Курсор захваченной руки при перетаскивании */
  scroll-behavior: auto; /* Отключаем плавную прокрутку при перетаскивании */
}





.carousel-card {
  display: inline-block;
  flex: 0 0 auto;
  width: 300px; /* Фиксированная ширина карточки */
  height: 200px; /* Фиксированная высота карточки */
  margin: 0; /* Убираем отступы между карточками */
  cursor: grab; /* Добавляем курсор для карточек */
}

.carousel-card:first-of-type {
margin-left: 40px;
}

.carousel-card:active {
  cursor: grabbing; /* Курсор захваченной руки при перетаскивании карточки */
}

.flip-card {
  perspective: 1000px;
  width: 95%;
  height: 100%;
  position: relative;
}

.flip-card-inner {
  width: 100%;
  /* height: 100%; */
  height: 200px;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
  border-radius: 50px; /* Скругление углов */
  margin: 1rem 0;
  font-family: 'PT Mono', monospace;
}

.flip-card-front {
  background-color: #1cdb66;
  color: black;
  box-shadow: 0 0 15px 5px rgba(0, 255, 0, 0.3); /* Зелёное свечение */
  border: 3px solid #000; /* Зелёный бордер */
}

.flip-card-front p {
  bottom: 0;
  position: absolute;
  left: 1rem;
  font-size: 2rem;
  font-weight: 900;
}

.icon-tag, .flip-card-front {
    fill: rgb(23, 34, 38) !important;
    color: rgb(23, 34, 38);
}

.fill_none {
  fill: none !important;
}

.flip-card-front .card-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Сохраняем соотношение сторон изображения */
}

.flip-card-back {
  background-color: #000;
  color: rgb(19, 220, 101);
  transform: rotateY(180deg);
  border-radius: 50px;
  box-shadow: 0 0 15px 5px rgba(19, 220, 101, .3);
  border: 3px solid rgb(19, 220, 101);
  display: block;
  overflow-x: hidden;
  text-wrap: wrap;
  font-size: .8rem;
  text-align: left;
}
.flip-card-back p {
bottom: .5rem;
position: absolute;
padding: 1rem;
user-select: none;
}
.card-title {

user-select: none;
position: absolute;
bottom: 1rem;
left: 1rem;
text-align: left;
font-weight: 900;
font-size: 1.8rem;
}

.svg_icons {
  top: 1rem;
  right: 1rem;
  position: absolute;
  width: 70px;
}
