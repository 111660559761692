/* LogoBlock.css */

.logo-block {
  background-color: #000;
  border: 1px solid #1cdb66;
  border-radius: 50px;
  padding: 2rem 1rem;
  max-width: 1000px;
  width: calc(100% - 100px);
  color: #1cdb66;
  font-family: 'PT Mono', monospace;
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.5);
  text-align: left;
  position: absolute;
  top: -320px;
  height: 500px;
  margin: 0;
  left: 50%;
  transform: translateX(-50%);
}

.logo_carusel {
  position: relative;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: -webkit-fill-available;
  margin-bottom: 20px;
}

.about {
  font-size: 2em;
  color: #fff;
}

.title {
  font-size: 2.85em;
  color: #fff;
  font-weight: bolder;
}

.title .highlight {
  color: #1cdb66;
}

.main-content {
  display: flex;
  align-items: flex-start;
}

.logo-container {
  flex: 1;
  padding-right: 20px;
}

#lb .logo {
  float: left;
  min-height: 170px;
  margin: 50px auto 0;
}

.description {
  display: flex;
  font-size: 1.8em;
  line-height: 1.5;
  color: #fff;
}

.white_text {
  color: #fff;
  font-size: 1.2rem;
  margin-left: .5rem;
}

.description .highlight {
  color: #1cdb66;
}

/* Адаптивные стили для мобильных устройств */
@media (max-width: 768px) {
  .logo-block {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    height: 180px;
    margin: 2rem auto;
    position: static;
    transform: none;
  }
  #lb .logo {
    margin: 0 auto;
    display: block;
    float: none;
    text-align: center;
  }

  .title {
    font-size: 2rem;
    text-align: center;
  }

  .description {
    font-size: 1.5rem;
    text-align: center;
    flex-direction: column;
    align-items: center;
  }

  

  .about {
    display: none;
  }

  .footer {
    flex-direction: column;
    align-items: center;
  }

  .description p {
    width: 100%;
  }
}

@media (max-width: 650px) {
  .title {
    font-size: 1.8rem;
    text-align: center;
  }

  .description {
    font-size: 1.4rem;
  }

  #lb #logoContent {
    width: 70%;
    height: auto;
    min-height: auto;
    max-height: 170px;
    margin: 0 auto;
    display: block;
    float: none;
    text-align: center;
  }

}

@media (max-width: 450px) {
  .logo-block {
    height: 130px;
  }
}