/* JobRolesBlock.css */

.job-roles-block {
  background-color: #000;
  border-radius: 20px;
  padding: 20px;
  max-width: 1000px;
  margin: 0rem auto 1rem auto;
  text-align: center;
  color: #fff;
  font-family: 'PT Mono', monospace;
}

.title {
  font-size: 2.5em;
  color: #fff;
  font-weight: bolder;
  text-align: left;
}

.roles-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 1rem;
}

.role {
  position: relative;
  width: 220px;
  height: 320px;
  border-radius: 50px;
  overflow: hidden;
  background: radial-gradient(circle, rgba(23, 220, 102, 0.5) 0%, rgba(0, 0, 0, 0.9) 91%, rgba(0, 0, 0, 1) 100%);
  border: .1px solid #17dc66;
  /* box-shadow: 0 0 15px rgba(23, 220, 102, 0.5); */
  transition: transform 0.3s ease-in-out;
  font-family: 'PT Mono', monospace;
}

.image-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.role-image {
  width: 100%;
  height: 315px;

  object-fit: cover;
  padding-top: 1.5rem;
  transition: transform 0.3s ease-in-out;
}
.designer_job {
  margin-top: 1rem;

}
.front_job {
  width: 217px;
margin-top: 0.7rem;
}
.byer_job {
  width: 219px;
margin-top: 2rem;
}
.backend_job {
}


.role-label {
  margin-top: 1rem;
  color: #fff;
  font-size: 1.2em;
}

.roles-container:hover .role:not(:hover) {
  transform: scale(0.9);
}

.role:hover {
  transform: scale(1.1);
}


@media (max-width: 768px) {
  .roles-container {
    flex-direction: column;
    align-items: center;
  }
  .role, .byer_job, .front_job {
    width: 100%;
    margin: 1rem 0;
  }
.role_container {
  max-width: calc(100% - 42px);
}
.role-image {
object-fit: fill;
}
}
