@font-face {
  font-family: 'ModulordRegular';
  src: url('/public/fonts/Modulord.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PT Mono';
  src: url('/public/fonts/IBM_Plex_Mono/IBMPlexMono-Regular.ttf') format('truetype');

}

.header-container {
  background-color: #000;
  padding: 20px;
  color: #17dc66;
  font-family: 'ModulordRegular', 'PT Mono', monospace; /* Добавьте 'ModulordRegular' */
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-width: 100%;
  box-sizing: border-box;
  position: relative;
}

#header .black_block {

  padding: 4rem 2rem !important;

}
.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
  width: 1000px;
  margin: 1rem auto;
  padding: 0 1rem;
}

.logo {
  max-height: 50px;
}

.left-logo {

  fill: #016b2a;
        stroke-width: 0px;
        height: 100px;
}

.right-logo {
 
  height: 100px;
}
.cls-1 {
  font-family: ModulordRegular, Modulord;
  font-size: 286.91px;
}

.cls-2 {
  letter-spacing: 0em;
}

.cls-3 {
  fill: #1cdb66;
  stroke-width: 0px;
}

.cls-4 {
  letter-spacing: -.03em;
}

.cls-5 {
  letter-spacing: 0em;
}

.content {
  flex: 1 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  position: relative;
  margin: 4rem auto 10rem auto;
  width: 100%;
  max-width: 1000px;
}

.typewriter-container {
  width: 100%;
  max-width: 800px;
  padding: 0 20px;
  box-sizing: border-box;
  text-align: left;
  margin-bottom: 10px;
  min-height: calc(36px * 1.4 * 4);
  font-family: 'ModulordRegular', 'PT Mono', monospace; 
  font-size: 24px; /* Измените размер шрифта при необходимости */
  line-height: 1.5; /* Увеличьте или уменьшите межстрочный интервал */
}

.date-display {
  margin-top: 10px;
  padding-left: 20px; /* Отступ слева, чтобы совпадал с текстом */
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* position: fixed;
  right: 12rem; */
}

.main-title {
  font-size: 36px;
  font-weight: bold;
  color: #fff;
  margin: 20px 0;
}

.highlight {
  color: #17dc66;
}

.custom-button {
  font-family: 'ModulordRegular', 'PT Mono', monospace;
  font-size: 1.5rem;
  color: #17dc66;
  background: none;
  border: .1px solid #17dc66;
  border-radius: 50px;
  padding: 10px 5px 10px 10px;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease;
  margin: 10px 0;
  position: relative;
  display: inline-block;
  width: 184px;
  text-align: center;
  text-decoration: none;
  background: radial-gradient(circle, rgba(23, 220, 102, .1) 0%, /* Bright Green Center */ rgba(7, 45, 24, 0.8) 0%, /* Dark Green Transition */ rgba(0, 0, 0, 1) 100%);
}

.custom-button:hover {
  transform: scale(1.05);
  color: #000;
  background: #17dc66;
}

.footer .info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
  position: absolute;
  left: 20px;
  bottom: 20px;
}

.footer .icon {
  margin-right: 10px;
  font-size: 20px;
}

.footer .text {
  color: #17dc66;
}

/* Добавьте этот код в ваш файл Header.css */

.custom-button::before {
  content: "\25A0";
  left: 1.2rem;
  color: transparent;
  position: absolute;
  font-size: 1rem;
  transition: color 0.3s ease, left 0.3s ease;
  top: 50%;
  transform: translateY(-50%);
}

.custom-button:hover::before {
  color: #000; /* Белая стрелка при наведении */

  
}


  /* Адаптивность */
  @media (max-width: 1200px) {
    .main-title {
      font-size: 32px;
    }
    
  }

  @media (max-width: 1100px) {
    .custom-button {
      width: 160px;
      font-size: 16px;
    }
    .buttons {
      right: 10px;
    }
    .content {
      margin-bottom: 0;
    }
    .content,  .buttons {
flex-direction: row;
      flex-wrap: wrap;
    }
    .custom-button {
      width: 160px;
      font-size: 16px;
      margin: 6rem .5rem;
    }
  }
  
  
  @media (max-width: 810px) {
    .main-title {
      font-size: 28px;
    }
    .custom-button {
      width: 140px;
      font-size: 20px;
      margin: 2rem 1rem 0rem 1rem;
    }
    .buttons {
      align-items: center;
      right: 10px;
      margin-top: 3rem;
    }
    .content {
      padding: 0;
    }
    .buttons {
      flex-direction: row;
      flex-wrap: wrap;
      position: relative;
      transform: none;
   
      justify-content: center;
  }
  }
  
  @media (max-width: 650px) {
    .content {
  margin: 4rem auto 3rem auto;
    }
  }

  @media (max-width: 604px) {
    .custom-button {
      margin-top: 1rem;
      font-size: 14px;
    }
    .date-display .text {
      font-size: 1.2rem !important;
  }
  }

  @media (max-width: 480px) {
    .main-title {
      font-size: 24px;
    }
    .custom-button {
      width: 120px;
      
    }
    .buttons {
      position: static;
      align-items: center;
      transform: none;
      margin-top: 20px;
    }
    .footer .info {
      font-size: 14px;
      bottom: 10px;
      left: 10px;
    }
  }



  