@font-face {
  font-family: 'ModulordRegular';
  src: url('/public/fonts/Modulord.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PT Mono';
  src: url('/public/fonts/IBM_Plex_Mono/IBMPlexMono-Regular.ttf') format('truetype');
}

.typewriter {
  display: flex;
  justify-content: flex-start; /* Выравниваем по началу строки */
  align-items: flex-start; /* Выравниваем текст по верхнему краю */
  width: 100%;
  box-sizing: border-box;
  min-width: 760px;
}

.typewriter-text {
  font-family: 'PT Mono', monospace;
  font-size: 38px;
  font-weight: bold;
  color: #fff;
  white-space: pre-wrap;
  text-align: left;
  line-height: 1.4;
  word-break: break-word;
  letter-spacing: normal;
  min-height: calc(38px * 1.4 * 4); /* Минимальная высота для 4 строк */
  max-width: 75%;
  overflow: hidden; /* Скрыть избыточный текст */
}

.typewriter-text span {
  display: inline;
  letter-spacing: -5px;
}

.typewriter-text .highlight {
  color: #1cdb66;
  font-family: ModulordRegular;
  letter-spacing: -1px;
  font-weight: 400;
}

.typewriter-text .greet {
  color: #fff; /* Оставляем цвет текста белым */
  font-family: ModulordRegular; /* Применяем шрифт ModulordRegular */
  letter-spacing: -1px;
  font-weight: 400;
}

.cursor {
  display: inline-block;
  width: 2px;
  height: 1.2em;
  background-color: #fff;
  animation: blink 1s steps(2, start) infinite;
}

@keyframes blink {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.info-block {
  font-size: 14px; /* Уменьшенный размер текста */
  overflow: hidden; /* Скрыть избыточный текст */
  max-height: 250px; /* Максимальная высота текста */
}




@media (max-width: 650px) {

.typewriter, .typewriter-text {
  width: 100%;
  min-width: 100%;
  font-size: 1.8rem;
  min-height: 1px;
}
.typewriter-container {
  min-height: calc(30px* 1.4* 4);
}
}