.ticker2-container {
  display: flex;
  overflow: hidden;
  width: 100vw; /* Ширина на весь экран */
  padding: .8rem 0;
  position: relative;
  box-sizing: border-box;
  white-space: nowrap; /* Предотвращение переноса текста */
  user-select: none;
  
}

.ticker2-text {
  display: flex; /* Используем Flexbox для выравнивания */
  white-space: nowrap;
  align-items: center; /* Центрируем по вертикали */
}

.ticker2-item {
  display: block; /* Flex для блока текста */
  align-items: center;
  padding: 0; /* Увеличиваем высоту текста и отступы */
  margin: 0 5px; /* Равномерные отступы между блоками */
  border-radius: 50px;
  width: 145px;
  display: block;
  fill: #000;
}

.marquee_one {
  animation: marquee1 100s infinite linear;
  animation-delay: -100s;
}

.marquee_two {
  animation: marquee2 100s infinite linear;
  animation-delay: -50s;
}


@keyframes marquee1 {
  0% {
      transform: translateX(100%);
  }
  100% {
      transform: translateX(-100%);
  }
}

@keyframes marquee2 {
  0% {
      transform: translateX(0%);
  }
  100% {
      transform: translateX(-200%);
  }
}