.ticker-container {
  display: flex;
  overflow: hidden;
  width: 100vw; /* Ширина на весь экран */
  padding: 1rem 0;
  position: relative;
  box-sizing: border-box;
  white-space: nowrap; /* Предотвращение переноса текста */
  height: 80px; /* Высота контейнера */
  user-select: none;
}

.ticker-text {
  display: flex; /* Используем Flexbox для выравнивания */
  white-space: nowrap;
  align-items: center; /* Центрируем по вертикали */
}

.ticker-item {
  display: inline-flex; /* Flex для блока текста */
  align-items: center;
  padding: 10px 20px; /* Увеличиваем высоту текста и отступы */
  margin: 0 5px; /* Равномерные отступы между блоками */
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 50px;
  font-family: 'PT Mono', monospace;
  font-size: 16px; /* Увеличиваем размер шрифта для видимости */
  font-weight: bold;
  color: #000;
  text-transform: uppercase;
}

.marquee_one {
  animation: marquee1 100s infinite linear;
  animation-delay: -100s;
}

.marquee_two {
  animation: marquee2 100s infinite linear;
  animation-delay: -50s;
}

@keyframes marquee1 {
  0% {
      transform: translateX(100%);
  }
  100% {
      transform: translateX(-100%);
  }
}

@keyframes marquee2 {
  0% {
      transform: translateX(0%);
  }
  100% {
      transform: translateX(-200%);
  }
}
