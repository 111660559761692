/* Контейнер для контактов */
@font-face {
    font-family: 'ModulordRegular';
    src: url('/public/fonts/Modulord.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'PT Mono';
    src: url('/public/fonts/IBM_Plex_Mono/IBMPlexMono-Regular.ttf') format('truetype');
  
  }
.contact-container {
    background-color: #0e0e0e; /* Черный фон */
    border: 1px solid #1cdb66; /* Зеленая обводка */
    border-radius: 50px; /* Закругленные углы */
    padding: 30px; /* Отступы */
    text-align: center; /* Центрирование текста */
    max-width: 1000px;
    margin: 3rem auto;
}

@media (max-width: 1100px) {
  .contact-container {
    margin-left: 40px;
    margin-right: 40px;
  }
  }

  @media (max-width: 768px) {
    .contact-container {
      margin-left: 30px;
      margin-right: 30px;
    }
    }

/* Заголовок блока */
.contact-title {
    font-family: 'ModulordRegular', 'PT Mono', monospace;
    color: #fff;
    font-size: 61px;
    letter-spacing: 7px;
    margin-bottom: 20px;
}

.contact-info {
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
    padding: 1rem 12rem 4rem 12rem;
  font-family: 'PT Mono', monospace;
  font-size: 1.5rem;
}


/* Контейнер для кнопок */
.button-container {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

/* Основные стили для кнопки */

#contact_block .buttons {
  position: relative;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#contact_block .custom-button {
  font-family: 'ModulordRegular', 'PT Mono', monospace;
  font-size: 1.5rem;
  color: #1cdb66;
  background: none;
  border: .1px solid #1cdb66;
  border-radius: 50px;
  padding: 10px 5px 10px 10px;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease;
  margin: 10px .5rem;
  position: relative;
  display: inline-block;
  width: 184px;
  text-align: center;
}

#contact_block .custom-button:hover {
  transform: scale(1.05);
  color: #000;
  background: #1cdb66;
}


@media (max-width: 1024px) {
.contact-info {
  padding:0;
  flex-direction: column;
  line-height: 3rem;
  margin-bottom: 1rem;
}
}
@media (max-width: 768px) {
.contact-title {
  font-size: 2rem
}
.contact-info {
  font-size: 1.5rem;
}
}