.ads-tech-block {
  background-color: #000;
  border: 1px solid #1cdb66;
  border-radius: 50px;
  padding: 2rem 1rem;
  max-width: 1000px;
  margin: 3rem auto 24rem auto;
  color: #1cdb66;
  font-family: "PT Mono", monospace;
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.5);
  text-align: left;
  position: relative;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: -webkit-fill-available;
  margin-bottom: 20px;
}

.about {
  font-size: 2em;
  color: #fff;
}

.title {
  font-size: 2.85em;
  color: #fff;
  font-weight: bolder;
}

.title .highlight {
  color: #1cdb66;
}

.main-content {
  display: flex;
  align-items: flex-start;
}

.logo-container {
  flex: 1;
  padding-right: 20px;
}

#atb .logo {
  float: left;
  width: 322px;
  min-height: 109px;
  margin: 0 2rem 1rem 0;
}

.description {
  flex: 2;
  font-size: 1.8em;
  line-height: 1.5;
  color: #fff;
}

.description p {
  text-indent: 4rem;
}

@media (max-width: 768px) {
  .description p {
    text-indent: 0;
  }
  .ads-tech-block { 
    margin-bottom: 2rem;
  }

}

.white_text {
  color: #fff;
  font-size: 1.2rem;
  margin-left: 0.5rem;
}
.description .highlight {
  color: #1cdb66;
}
/* .description img{
    float: left;
    padding: .3rem 2rem 0rem 0rem;
  } */

.footer {
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
  margin-top: 20px;
}

.statistic {
  text-align: center;
  font-size: 1.5em;
  align-items: center;
  display: flex;
}

.number {
  font-size: 1.3em;
}

@media (max-width: 1100px) {
  .ads-tech-block {
    margin-left: 40px;
    margin-right: 40px;
  }
}


@media (max-width: 870px) {
  #atb .about {
    display: none;
  }
  .title {
    font-size: 2rem !important;
    text-align: center !important;
  }
  .description {
    font-size: 1.5rem;
    text-align: center;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 800px) {

  .description {
    font-size: 1.2rem !important;
    text-align: center;
    flex-direction: column;
    align-items: center;
  }

  #atb .footer {
    align-items: center;
    flex-direction: column;
  }
  #atb .description p {
    width: 98%;
  }
}

@media (max-width: 650px) {
  #atb .title {
    font-size: 2.3rem;
    text-align: center;
  }
  #atb .description {
    font-size: 1.8rem;
  }
  #atb .logo {
    /* width: 100%; */
    margin: 0 auto;
    display: block;
    float: none;
    text-align: center;
  }
}

@media (max-width: 480px) {
  #atb .logo {
    width: 100%;
  }
}
