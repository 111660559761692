html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;  
}

body {
  /* position: relative; */
}



section {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  width: 100%;
  /* overflow-x: hidden; */
}
.black_block {
  background: #000;
  margin: 0 auto;


  padding: 1rem 0;
  width: 100%;

}

.bl_center {
  box-shadow: 0 0 33px 3px #17dc66;
  border-radius: 57px;

}

.bl_top {
  border-radius: 0 0px 57px 57px;
}

.bl_bot {
  border-radius: 57px 57px 0 0;
}

.main {
  max-width: 100%;
  width: 100%;
  height: 100vh;
  background: #000;
  position: relative;
  z-index: 1;
}

span {
  padding-right: 3px;
}


.test {

  background-color: #FFF;
}

.black_block {
  position: relative;
  z-index: 1;
}


.ads-tech-block, .contact-container, .info-block {
  
  background: radial-gradient(circle, 
  rgba(23, 220, 102, .1) 0%, /* Bright Green Center */
  rgba(7, 45, 24, 0.8) 0%,   /* Dark Green Transition */
  rgba(0, 0, 0, 1) 100%); 
}


@media (max-width: 768px) {
/* .black_block {
  padding: 2rem 0;
} */
}

